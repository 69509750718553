import { render, staticRenderFns } from "./WriterOrderInfo.vue?vue&type=template&id=710b434c&scoped=true&"
import script from "./WriterOrderInfo.vue?vue&type=script&lang=js&"
export * from "./WriterOrderInfo.vue?vue&type=script&lang=js&"
import style0 from "./WriterOrderInfo.vue?vue&type=style&index=0&id=710b434c&lang=scss&scoped=true&"
import style1 from "./WriterOrderInfo.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710b434c",
  null
  
)

export default component.exports